<template>
  <div class="overflow-auto lg:overflow-hidden">
    <table class="table-blok">
      <thead>
        <tr>
          <th v-for="(th, j) in item.content.thead" :key="j" v-html="th.value" />
        </tr>
      </thead>
      <tbody>
        <tr v-for="(tr, i) in item.content.tbody" :key="i">
          <template v-for="(td, j) in tr.body">
            <td v-if="td.value !== '_'" :key="j" :colspan="colspan(tr, j)" v-html="td.value" />
          </template>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import { Blok } from 'src/modules/vsf-storyblok-module/components'

export default {
  name: 'TableBlok',
  extends: Blok,
  methods: {
    colspan (tr, j) {
      for (let i = 1; i < tr.body.length - j; i++) {
        const td = tr.body[i + j]

        if (td.value !== '_') {
          return i > 1 ? i : null
        }
      }
      return tr.body.length - j > 1 ? tr.body.length - j : null
    }
  }
}
</script>
